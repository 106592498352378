import common from "@/utils/common";

export default {
    color: common.G2ChartTheme.colors20,
    title: [
        {
            text: 'RPKM distribution',
            left: 'center'
        }
    ],
    dataset: [
        {
            // 这个 dataset 的 index 为 `0`。
            source: []
        },
        {
            // 这个 dataset 的 index 为 `1`。
            transform: {
                type: 'boxplot',
                config: {},
                // 配置为 `true` 后， transform 的结果
                // 会被 console.log 打印出来。
                // print: true
            }
            // 这个 "boxplot" transform 生成了两个数据：
            // result[0]: boxplot series 所需的数据。
            // result[1]: 离群点数据。
            // 当其他 series 或者 dataset 引用这个 dataset 时，他们默认只能得到
            // result[0] 。
            // 如果想要他们得到 result[1] ，需要额外声明如下这样一个 dataset ：
        },
        {
            // 这个 dataset 的 index 为 `2`。
            // 这个额外的 dataset 指定了数据来源于 index 为 `1` 的 dataset。
            fromDatasetIndex: 1,
            // 并且指定了获取 transform result[1] 。
            fromTransformResult: 1
        }
    ],
    tooltip: {
        axisPointer: {
            type: 'shadow'
        },
        formatter: function (p) {
            if (p.seriesType === "boxplot") {
                return `
                <div>
                    <div style="margin: 0px 0 0;line-height:1;">
                        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${p.value[0]}</div>
                        <div style="margin: 10px 0 0;line-height:1;">
                            <div style="margin: 0;line-height:1;">
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">low</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${p.value[1].toFixed(2)}</span>
                                <div style="clear:both"></div>
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Q1</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${p.value[2].toFixed(2)}</span>
                                <div style="clear:both"></div>
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">median</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${p.value[3].toFixed(2)}</span>
                                <div style="clear:both"></div>
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Q3</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${p.value[4].toFixed(2)}</span>
                                <div style="clear:both"></div>
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">high</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${p.value[5].toFixed(2)}</span>
                                <div style="clear:both"></div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                `
            }else if(p.seriesType === "scatter"){
                return  `
                <div>
                    <div style="margin: 0px 0 0;line-height:1;">
                        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;"> 异常值</div>
                        <div style="margin: 10px 0 0;line-height:1;">
                            <div style="margin: 0;line-height:1;">
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${p.value[0]}</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${p.value[1]}</span>
                                <div style="clear:both"></div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                `
            }
        }
    },
    toolbox:{
        show:true,
        right:20,
        itemSize:20,
        feature: {
            restore: {
                icon:"image:///images/echarts-toolbox-refresh.png"
            },
            saveAsImage:{
                show:true,                      // 是否显示工具
                title:'保存为图片',                // 工具标签
                icon:'image:///images/echarts-toolbox-download.png',             // 工具 icon
                type:'png',                     // 自定义保持图片的后缀
                // name:'RPKM distribution',                // 自定义保持图片的名称，默认获取 tite 标题的 text 内容作为文件名称
                backgroundColor:'#FFFFFF',         // 保持图片的背景颜色，默认白色
                connectedBackgroundColor:'red', // 如果图表使用了 echarts.connect 对多个图表进行联动，则在导出图片时会导出这些联动的图表。该配置项决定了图表与图表之间间隙处的填充色。
                excludeComponents:['toolbox'],  // 保持图片时，图片中忽略的组件列表
            },
        }
    },
    grid: {
        containLabel: true,
        top:30,
        left: 25,
        right: 10,
        bottom: '10%'
    },
    xAxis: {
        type: 'category',
        boundaryGap: true,
        splitArea: {
            show: true
        },
        axisLine:{
            show:true,
            lineStyle:{
                color:"#aaa",
                width:2
            }
        },
        axisLabel: {
            //强制显示所有标签
            interval: 0
        },
        splitLine: {
            show: false
        }
    },
    yAxis: {
        type: 'value',
        name: 'log2(rpkm)',
        nameLocation:"middle",
        nameGap:20,
        nameTextStyle:{color:"#595959"},
        axisLine:{
            show:true,
            lineStyle:{
                color:"#aaa",
                width:2
            }
        },
        splitArea: {
            show: true
        }
    },
    dataZoom: [
        {
            type: 'inside',
            start: 0,
            end: 50
        },
        {
            show: true,
            type: 'slider',
            top: '90%',
            xAxisIndex: [0],
            start: 0,
            end: 50
        }
    ],
    series: [
        {
            name: 'boxplot',
            type: 'boxplot',
            colorBy:"data",
            // Reference the data from result[0].
            // 这个 series 引用 index 为 `1` 的 dataset 。
            datasetIndex: 1,
        },
        {
            name: 'outlier',
            type: 'scatter',
            // colorBy:"data",
            itemStyle:{
                color:"#9b9999"
            },
            // 这个 series 引用 index 为 `2` 的 dataset 。
            // 从而也就得到了上述的 transform result[1] （即离群点数据）
            datasetIndex: 2
        }
    ]
};
